<template>
  <div>
    <ModalMailDetail
      v-if="selectedMailLog && showMailDetail"
      :showModal="selectedMailLog && showMailDetail"
      :onClickCancel="hideModal"
      :mailLog="selectedMailLog"
    />

    <table class="table is-fullwidth is-striped is-hoverable">
      <thead>
        <tr>
          <th>{{
                $t('Components.Reservation.ReservationMailLog.Table_HeadSentOn')
              }}</th>
          <th>{{
                $t('Components.Reservation.ReservationMailLog.Table_HeadFrom')
              }}</th>
          <th>{{
                $t('Components.Reservation.ReservationMailLog.Table_HeadTo')
              }}</th>
          <th>{{
                $t('Components.Reservation.ReservationMailLog.Table_HeadSubject')
              }}</th>
          <th>{{
                $t('Components.Reservation.ReservationMailLog.Table_HeadStatus')
              }}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(mailLog, index) in mailLogs" :key="index">
          <td>
            {{ mailLog.CreatedOn | longDateFormat($i18n.locale) }}
            <div>{{ mailLog.CreatedByName }}</div>
          </td>
          <td>{{ mailLog.From }}</td>
          <td>{{ mailLog.To }}</td>
          <td>{{ mailLog.Subject }}</td>
          <td>{{ mailLog.Status }}</td>
          <td>
            <a @click="setShowMailDetail(mailLog)" class="is-size-7">
              <span class="icon is-small">
                <font-awesome-icon :icon="['fas', 'share']" />
              </span>
              <span>{{
                $t('Components.Dashboards.IncomingComments.Button_Detail')
              }}</span>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'

const ModalMailDetail = () => import('@/components/Mails/ModalMailDetail')

export default {
  components: {
    ModalMailDetail,
  },

  props: {
    mailLogs: {
      type: Array,
      default: function() {
        return []
      },
    },
  },

  data() {
    return {
      selectedMailLog: null,
      showMailDetail: false,
    }
  },

  computed: {},

  created() {},

  methods: {
    setShowMailDetail(mailLog) {
      this.selectedMailLog = mailLog
      this.showMailDetail = true
    },

    hideModal() {
      this.selectedMailLog = null
      this.showMailDetail = true
    },
  },
}
</script>

<style></style>
